<template>
    <v-app>
        <v-navigation-drawer
                fixed
                app
                v-model="drawer"
                :clipped="clipped"
                persistent
                width="270"
        >

            <v-main class="py-6 px-0 text-center">
                    <img src="/static/img/logo.svg" width="237" height="105" style="filter: drop-shadow(0px 0px 1px rgba(88, 255, 53, 0.5));"/>
<!--                    <div style="position: absolute; font-size: 8px; font-weight: 700; bottom: 22px; right: 30px; color: #259a00;">VERSIOON 9.0.1</div>-->
            </v-main>
            <v-divider></v-divider>
            <v-row no-gutters class="px-4" v-if="hasLinkedUsers">
                <v-select
                        class="user-select sidebar"
                        :prepend-icon="mdiAccountSwitch()"
                        ref="requisiteTypes"
                        :items="linkedUsers"
                        v-model="selectedUser"
                        :item-text="linkedUserName"
                        :item-value="linkedUserSelectValue"
                        no-data-text="Pole ühtegi seotud kasutajat"
                        data-cy="linked-user-select"
                ></v-select>
            </v-row>
            <v-divider v-if="hasLinkedUsers"></v-divider>
            <v-list class="main-menu pt-0 pb-16" :expand="true">
                <template v-for="(item, itemIndex) in items">
                    <v-list-group
                            v-if="item.children"
                            v-model="menuState[itemIndex]"
                            :key="item.text"
                            :class="{ 'sublink-active': isGroupActive(item) }"
                            :style="item.highlight ? 'background-color:#bfffa9 !important' : null"
                    >
                        <template v-slot:activator>
                            <v-row no-gutters :key="item.text" >
                                <v-list-item-action class="mr-4">
                                    <v-icon>{{ item.icon }}</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-badge :color="hasWarnings ? (hasErrorWarnings ? '#b72d2d' : '#8e8e8e') : 'transparent'" v-if="item.badge" overlap offset-y="24" offset-x="28"
                                             :style="!hasWarnings && 'opacity: 0.5'" :content="warningCount">
                                        <v-list-item-title>
                                            {{ item.heading }}
                                        </v-list-item-title>
                                    </v-badge>
                                    <v-badge v-else-if="item.statusIndicator && showAccountStatusWarning" color="error" overlap offset-y="24" offset-x="24">
                                        <template #badge>
                                            <v-icon dark style="font-size:16px; font-weight: 400;margin-top:-2px">priority_high</v-icon>
                                        </template>
                                        <v-list-item-title>
                                            {{ item.heading }}
                                        </v-list-item-title>
                                    </v-badge>
                                    <v-badge v-else-if="item.setupWizard" overlap offset-y="24" offset-x="24">
                                        <template #badge>
                                            <v-icon x-large style="font-size:16px; font-weight: 400;margin-top:-3px">
                                                {{ item.setupWizard }}</v-icon>
                                        </template>
                                        <v-list-item-title>
                                            {{ item.heading }}
                                        </v-list-item-title>
                                    </v-badge>
                                    <v-badge v-else-if="item.highlight" color="transparent" overlap offset-y="22" offset-x="20">
                                        <template #badge>
                                            <v-icon size="16" style="color: green; font-size:12px; font-weight: 400;margin-top:-3px">
                                                {{ mdiCreation() }}</v-icon>
                                        </template>
                                        <v-list-item-title>
                                            {{ item.heading }}
                                        </v-list-item-title>
                                    </v-badge>
                                    <v-list-item-title v-else>
                                        {{ item.heading }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-row>
                        </template>
                        <v-list-item
                                v-for="(child, i) in item.children"
                                :key="item.text+'_el_'+i"
                                @click.stop="()=> {}"
                                :to="{ name: child.routeName, params: child.routeName !== 'requisitePlan' ? currentYear : nextYearIfAlmostYearEnd}"
                        >
                            <v-list-item-action v-if="child.icon" class="ml-7 mr-4">
                                <v-icon>{{ child.icon }}</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-badge v-if="child.setupWizard" overlap offset-y="22" offset-x="25">
                                    <template #badge>
                                        <v-icon size="17" style="font-size:16px; font-weight: 400;margin-top:-3px">
                                            {{ child.setupWizard }}</v-icon>
                                    </template>
                                    <v-list-item-title>
                                        {{ child.text }}
                                    </v-list-item-title>
                                </v-badge>
                                <v-list-item-title v-else>
                                    {{ child.text }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-group>
                    <v-list-item v-else @click="() => {}"
                                 :key="'s' + item.text"
                                 :to="{ name: item.routeName, params: item.routeName !== 'requisitePlan' ? currentYear : nextYearIfAlmostYearEnd}"
                                 color="primary"
                                 :style="item.highlight ? 'background-color:#bfffa9 !important' : null"
                                 :exact="item.exact">
                        <v-list-item-action class="mr-4">
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
<!--                            <v-badge color="primary" v-if="item.text === 'Tööde haldus'" overlap offset-y="22" offset-x="40" content="UUS">-->
<!--                                <template #badge>-->
<!--                                    <span style="font-size:13px !important; font-weight: 700; letter-spacing:0.03rem;border-radius: 2px !important">UUS</span>-->
<!--                                </template>-->
<!--                            </v-badge>-->

                            <v-badge :color="hasWarnings ? (hasErrorWarnings ? '#b72d2d' : '#8e8e8e') : 'transparent'" v-if="item.badge" overlap offset-y="22" offset-x="28"
                                     :style="!hasWarnings && 'opacity: 0.5'" :content="warningCount">
                                <v-list-item-title>
                                    {{ item.text }}
                                </v-list-item-title>
                            </v-badge>
                            <v-badge v-else-if="item.statusIndicator && showAccountStatusWarning" color="error" overlap offset-y="22" offset-x="25">
                                <template #badge>
                                    <v-icon dark size="16" style="font-size:12px; font-weight: 400;margin-top:-2px">
                                        {{ mdiExclamationThick() }}</v-icon>
                                </template>
                                <v-list-item-title>
                                    {{ item.text }}
                                </v-list-item-title>
                            </v-badge>
                            <v-badge v-else-if="item.setupWizard" overlap offset-y="22" offset-x="25">
                                <template #badge>
                                    <v-icon size="17" style="font-size:16px; font-weight: 400;margin-top:-3px">
                                        {{ item.setupWizard }}</v-icon>
                                </template>
                                <v-list-item-title>
                                    {{ item.text }}
                                </v-list-item-title>
                            </v-badge>
                            <v-badge v-else-if="item.highlight" color="transparent" overlap offset-y="22" offset-x="25">
                                <template #badge>
                                    <v-icon size="16" style="color: green;font-size:12px; font-weight: 400;margin-top:-2px">
                                        {{ mdiCreation() }}</v-icon>
                                </template>
                                <v-list-item-title>
                                    {{ item.text }}
                                </v-list-item-title>
                            </v-badge>
                            <v-list-item-title v-else>
                                {{ item.text }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                </template>
            </v-list>
        </v-navigation-drawer>
        <v-app-bar
                app
                color="secondary"
                dark
                flat
                :clipped="clipped"
        >
            <v-app-bar-nav-icon large class="mr-2 ml-1" @click.stop="drawer = !drawer">
                <v-icon x-large>menu</v-icon>
            </v-app-bar-nav-icon>
            <v-toolbar-title style="width: 100%" class="ml-0 pl-1">
<!--                <v-toolbar-side-icon large class="mr-2 ml-1" @click.stop="drawer = !drawer">-->
<!--                    <v-icon large>menu</v-icon>-->
<!--                </v-toolbar-side-icon>-->
                <!--<span class="hidden-sm-and-down">Põlluraamatu ABIMASIN</span>-->
                <!--<span>{{pageTitle}}</span>-->
                <v-breadcrumbs :items="pageTitle">
                    <template #item="props">
                      <span style="color:white" v-if="props.item.to">
                        <router-link :to="props.item.to"
                                     :disabled="props.item.disabled">{{ props.item.text }}</router-link>
                      </span>
                        <span v-else>{{ props.item.text }}</span>
                    </template>
                    <template #divider>
                        <v-icon :style="$vuetify.breakpoint.smAndUp ? 'font-size: 24px;' : ''">chevron_right</v-icon>
                    </template>
                </v-breadcrumbs>
                <!--<breadcrumbs/>-->
            </v-toolbar-title>

            <v-spacer></v-spacer>
            <v-subheader v-if="isDemoMode" style="color:white; font-size: 20px">
                DEMO <span v-if="$vuetify.breakpoint.smAndUp" style="font-weight:100;margin-left:6px">
        <v-btn color="white" light small @click="closeDemoAndRegister">
          Registreeri konto
        </v-btn>
      </span>
            </v-subheader>
            <!--<v-tooltip top>-->
            <!--<v-btn large icon slot="activator">-->
            <!--<v-icon>notifications</v-icon>-->
            <!--</v-btn>-->
            <!--<span>Teateid ei ole</span>-->
            <!--</v-tooltip>-->

            <!--<v-tooltip top>-->
            <!--<v-btn v-if="$vuetify.breakpoint.smAndDown" large icon slot="activator" dark @click.stop="showContactMessageModal = !showContactMessageModal">-->
            <!--<v-icon>group</v-icon>-->
            <!--</v-btn>-->
            <!--<v-btn v-else text slot="activator" dark @click.stop="showContactMessageModal = !showContactMessageModal">-->
            <!--<v-icon style="margin-right:5px">group</v-icon>-->
            <!--Vaheta konto-->
            <!--</v-btn>-->
            <!--<span>Vaheta konto</span>-->
            <!--</v-tooltip>-->
            <!--<v-select-->
            <!--v-if="hasLinkedUsers && !$vuetify.breakpoint.smAndDown"-->
            <!--prepend-icon="group"-->
            <!--ref="requisiteTypes"-->
            <!--:items="linkedUsers"-->
            <!--class="user-select header"-->
            <!--style="white-space: nowrap;"-->
            <!--v-model="selectedUser"-->
            <!--:item-text="linkedUserName"-->
            <!--:item-value="linkedUserSelectValue"-->
            <!--no-data-text="Pole ühtegi seotud kasutajat"-->
            <!--&gt;</v-select>-->
            <SetupWizard v-if="shouldShowSetupWizard && $vuetify.breakpoint.mdAndUp"></SetupWizard>
            <div v-if="!isDemoMode">
                    <v-btn v-if="$vuetify.breakpoint.smAndDown" large icon
                           @click.stop="showContactMessageModal = !showContactMessageModal">
                        <v-icon>message</v-icon>
                    </v-btn>
                    <v-btn v-else text
                           @click.stop="showContactMessageModal = !showContactMessageModal" data-cy="send-feedback-open-button">
                        <v-icon style="margin-right:5px">message</v-icon>
                        Võta ühendust
                    </v-btn>
            </div>

            <div>
                <v-btn v-if="$vuetify.breakpoint.smAndDown" large icon
                       :style="{marginRight: $vuetify.breakpoint.smAndDown ? '0px' : '20px'}" @click="logout"
                       >
                    <v-icon style="margin-right:5px">exit_to_app</v-icon>
                </v-btn>
                <v-btn v-else text :style="{marginRight: $vuetify.breakpoint.smAndDown ? '0px' : '20px'}" @click="logout">
                    <v-icon style="margin-right:5px">exit_to_app</v-icon>
                    {{isDemoMode ? "Välju" : "Logi välja"}}
                </v-btn>
            </div>
        </v-app-bar>
        <v-main>
            <router-view></router-view>
        </v-main>

        <v-snackbar :color="messageBarColor" :transition="isErrorMessage ? 'shake-error' : 'v-snack-transition'" v-model="messageBar" :min-height="messageBarIsTop ? null : 100" :top="messageBarIsTop" :centered="!messageBarIsTop" :timeout="-1">
          <v-row class="font-weight-bold justify-center align-center">
            <v-icon color="white" class="mx-3">{{messageBarIcon}}</v-icon>
            {{ messageBarText }}
            <v-icon v-if="isErrorMessage" color="white" class="mx-3">{{messageBarIcon}}</v-icon>
          </v-row>
        </v-snackbar>

        <v-dialog :fullscreen="$vuetify.breakpoint.smAndDown" v-model="showContactMessageModal" absolute
                  max-width="600px">
            <sendMessageModal @closeModal="showContactMessageModal = !showContactMessageModal">
            </sendMessageModal>
        </v-dialog>

        <v-dialog v-if="demoWarning" v-model="demoWarningOpen" persistent max-width="500">
            <v-card>
                <v-card-title class="grey lighten-4 text-h6">Piirangutega demoversioon</v-card-title>
                <info-modal-container-wrapper>
                    Põlluraamatu demoversioon on <strong>piiratud funktsionaalsusega</strong>. Selle eesmärk on
                    anda ainult
                    <strong>kiire ettekujutus</strong> rakenduse olemusest. <br/>
                    Mõned võimalused on <strong>piiratud</strong> või <strong>puuduvad</strong> täielikult.<br/>
                    <template #footer>
                        <v-btn color="primary" elevation="0" dark @click.native="demoWarningOpen = false">Selge</v-btn>
                    </template>
                </info-modal-container-wrapper>
            </v-card>
        </v-dialog>
        <pria-enabled-notification-modal />
        <v-dialog :fullscreen="$vuetify.breakpoint.smAndDown" v-if="trialWarning" v-model="trialWarningOpen" persistent
                  max-width="550">
            <v-card>
                <v-card-title class="grey lighten-4 text-h6">Prooviaeg on lõppenud!</v-card-title>
                <info-modal-container-wrapper>
                    <span>Teie konto tasuta prooviaeg lõppes <strong>{{trialEndDate}}</strong>.</span>
                    <span :style="trialAccountDeterminationWarning ? 'color: #b72d2d;font-weight: 500': ''">
                        Teenuse edasiseks kasutamiseks palun esitage tellimus!
                    </span>
                    <template #footer>
                        <v-btn v-if="!trialAccountDeterminationWarning" color="gray" text class="mx-2"
                               @click.native="trialWarningOpen = false">
                            <v-icon left>close</v-icon>
                            Hiljem
                        </v-btn>
                        <v-btn v-if="trialAccountDeterminationWarning" color="primary" primary :to="{ name: 'pollud', params: currentYear}"
                               class="mr-2"
                               :block="$vuetify.breakpoint.mdAndDown"
                               @click.native="extendSubscriptionRedirectClicked" title="Põldude haldus">
                            <v-icon left>{{ mdiLandFields() }}</v-icon>
                            Uuenda põllud
                        </v-btn>
                        <v-btn color="primary" primary :to="{ name: 'account'}"
                               :block="$vuetify.breakpoint.mdAndDown"
                               @click.native="trialWarningOpen = false" title="Konto haldus">
                            <v-icon left>account_circle</v-icon>
                            Tellimust esitama
                        </v-btn>
                    </template>
                </info-modal-container-wrapper>
            </v-card>
        </v-dialog>
        <v-dialog :fullscreen="$vuetify.breakpoint.smAndDown" v-if="increaseOrderWarning && (this.$route.path !== '/konto' && !/^\/pollud.*\/\d{4}$/.test(this.$route.path))"
                  v-model="increaseOrderWarningOpen" :persistent="increaseOrderWarningIsPersistent" max-width="470">
            <v-card>
                <v-card-title class="grey lighten-4 text-h6">Esitage tellimus lisandunud pindalale!</v-card-title>
                <info-modal-container-wrapper>
                    Põldude kogupindala ületab tasutud pindala suurust. <br/>
                    Esitage uus tellimus, et jätkata teenuse kasutamisega.
                    <template #footer>
                        <v-btn v-if="!increaseOrderWarningIsPersistent" color="gray" text class="mx-2"
                               @click.native="increaseOrderWarningOpen = false">
                            <v-icon left>close</v-icon>
                            Hiljem
                        </v-btn>
                      <v-btn v-if="increaseOrderWarning && increaseOrderWarningIsPersistent" color="primary" primary :to="{ name: 'pollud', params: currentYear}"
                             class="mr-2"
                             :block="$vuetify.breakpoint.mdAndDown"
                             @click.native="extendSubscriptionRedirectClicked" title="Põldude haldus">
                        <v-icon left>{{ mdiLandFields() }}</v-icon>
                        Uuenda põllud
                      </v-btn>
                        <v-btn color="primary" primary :to="{ name: 'account'}"
                               :block="$vuetify.breakpoint.mdAndDown"
                               @click.native="increaseSubscriptionRedirectClicked" title="Konto haldus">
                            <v-icon left>account_circle</v-icon>
                            Tellimust esitama
                        </v-btn>
                    </template>
                </info-modal-container-wrapper>
            </v-card>
        </v-dialog>
        <v-dialog :fullscreen="$vuetify.breakpoint.smAndDown" v-if="updateYearlySubscriptionWarning && (this.$route.path !== '/konto' && !/^\/pollud.*\/\d{4}$/.test(this.$route.path))" :persistent="isWellIntoSeason"
                  v-model="updateYearlySubscriptionWarningOpen" max-width="470">
            <v-card>
                <v-card-title class="grey lighten-4 text-h6">Pikendage tellimust!</v-card-title>
                <info-modal-container-wrapper>
                    Pikendage Abimasina tellimus ka aastaks {{currentYear.year}}. <br/>Uuendage ka enne põldude pindalad, et tellimus saaks arvestatud õigesti.
                    <template #footer>
                        <v-btn v-if="!updateYearlySubscriptionWarningPersistent" color="gray" text class="mx-2"
                               @click.native="updateYearlySubscriptionWarningOpen = false">
                            <v-icon left>close</v-icon>
                            Hiljem
                        </v-btn>
                        <v-btn v-if="updateYearlySubscriptionWarning" color="primary" primary :to="{ name: 'pollud', params: currentYear}"
                               :block="$vuetify.breakpoint.mdAndDown"
                               class="mr-2"
                               @click.native="extendSubscriptionRedirectClicked" title="Põldude haldus">
                            <v-icon left>{{ mdiLandFields() }}</v-icon>
                            Uuenda põllud
                        </v-btn>
                        <v-btn color="primary" primary :to="{ name: 'account'}"
                               :block="$vuetify.breakpoint.mdAndDown"
                               @click.native="extendSubscriptionRedirectClicked" title="Konto haldus">
                            <v-icon left>account_circle</v-icon>
                            Tellimust pikendama
                        </v-btn>
                    </template>
                </info-modal-container-wrapper>
            </v-card>
        </v-dialog>
        <v-dialog :fullscreen="$vuetify.breakpoint.smAndDown" v-if="shouldShowNewAccountWelcome"
                  v-model="newAccountWelcome" max-width="600">
            <v-card class="py-4 px-4">
                <v-card-title class="text-h2" style="text-align: center; margin-bottom: -20px;color:#61715c;">
                    <v-col>Tere tulemast!</v-col>
                </v-card-title>
                <info-modal-container-wrapper>

                    <div class="setupWizard splash mb-4" style="text-align: center">
                        <div class="ring-container">
                            <div class="ringring"></div>
                            <div class="circle"></div>
                        </div>
                        Alustamiseks oleks mõistlik teha järgmised sammud:
                    </div>
                    <v-row no-gutters class="fill-height" align="center" justify="center" :class="$vuetify.breakpoint.smAndUp ? 'row' : 'column'">
                        <v-col style="max-width: 360px">

                            <v-row justify="center">
                            <v-btn block x-large color="primary" primary :to="{ name: 'categories'}" class="justify-start pl-3"
                                   @click.native="newAccountWelcome = false" title="Tööde kategooriad">
                                <v-icon style="opacity: 0.8" class="mr-0" size="46">{{ mdiNumeric1() }}</v-icon>
                                Määra tööde tüübid
                                <v-icon style="margin-left:8px" right size="26">{{ mdiHammerWrench() }}</v-icon>
                            </v-btn>
                            </v-row>
                            <v-row justify="center">
                                <v-btn block x-large color="primary" primary :to="{ name: 'seeds'}" class="justify-start pl-3"
                                       @click.native="newAccountWelcome = false" title="Tarvikute lisamine">
                                    <v-icon style="opacity: 0.8" class="mr-0" size="46">{{ mdiNumeric2() }}</v-icon>
                                    lisa enda tarvikud
                                    <v-icon style="margin-left:8px" size="28">{{ mdiPackageVariantClosed () }}</v-icon>
                                </v-btn>
                            </v-row>
                            <v-row justify="center">
                                <v-btn block x-large color="primary" class="justify-start pl-3" primary :to="{ name: 'pollud', params: currentYear}"
                                       @click.native="newAccountWelcome = false" title="Põllud">
                                    <v-icon style="opacity: 0.8" class="mr-0" size="46">{{ mdiNumeric3() }}</v-icon>
                                    Lisa enda põllud
                                    <v-icon style="margin-left:8px">{{ mdiLandFields() }}</v-icon>
                                </v-btn>
                            </v-row>
                        </v-col>
                    </v-row>
                    <template #footer>
                        <v-btn text @click.native="newAccountWelcome = false" title="Sulge" data-cy="later-button">
                            <v-icon color="gray">close</v-icon>
                            Hiljem
                        </v-btn>
                    </template>
                </info-modal-container-wrapper>
            </v-card>
        </v-dialog>
    </v-app>
</template>

<script>

import sendMessageModal from "./components/modal/sendMessageModal.vue"
import {ACCOUNT_STATUS, ICONS} from "./constant/constants.js"
import _ from "lodash"
import {EventBus} from './event-bus.js';
import infoModalContainerWrapper from "./components/infoModalContainerWrapper";
import {
    mdiAccountCircle,
    mdiAccountMultipleOutline,
    mdiAccountSwitch,
    mdiAlert,
    mdiAlertCircleOutline,
    mdiAnimation,
    mdiCalendarClock,
    mdiCog,
    mdiCreation,
    mdiDatabaseRefreshOutline,
    mdiDotsTriangle,
    mdiExclamationThick,
    mdiExpandAllOutline,
    mdiFlaskOutline,
    mdiFolderWrenchOutline,
    mdiHammerWrench,
    mdiHelp,
    mdiHelpCircle,
    mdiHomeSiloOutline,
    mdiLandFields,
    mdiLandPlots,
    mdiLeaf,
    mdiNumeric1,
    mdiNumeric1BoxOutline,
    mdiNumeric1CircleOutline,
    mdiNumeric2,
    mdiNumeric2BoxOutline,
    mdiNumeric2CircleOutline,
    mdiNumeric3,
    mdiNumeric3CircleOutline,
    mdiPackageVariantClosed,
    mdiPackageVariantClosedCheck,
    mdiPaletteSwatchOutline,
    mdiSeedOutline,
    mdiSeedPlusOutline,
    mdiSproutOutline,
    mdiStackOverflow
} from "@mdi/js";
import PriaEnabledNotificationModal from "@/pria/priaEnabledNotificationModal.vue";

export default {
        data: () => ({
            showContactMessageModal: false,
            demoWarningOpen: true,
            trialWarningOpen: true,
            increaseOrderWarningOpen: true,
            updateYearlySubscriptionWarningOpen: true,
            newAccountWelcome: true,
            loadingFieldsCompleted: false,
            loadingRequisitesCompleted: false,
            clipped: null,
            dialog: false,
            drawer: null,
            selectedUser: null,
            menuState: [null, null, false, false, false, false, null]

        }),
        components: {
            PriaEnabledNotificationModal,
            sendMessageModal,
            SetupWizard: () => import('./viewes/setupWizard.vue'),
            infoModalContainerWrapper
        },
        props: {
            source: String
        },
        mounted() {
            EventBus.$on('showFeedbackModal', () => {
                this.showContactMessageModal = true

            });
        },

        watch: {
            selectedUser(newUserId) {
                if (this.currentUserId !== newUserId) {
                    this.$store.dispatch('switchUser', {_id: newUserId}).then(() => {
                        if (/.*\/pollud\/\d+\/\w+$/.test(location.hash)) {
                            location.hash = "";
                        }
                        location.reload();
                    }).catch((e) => {
                        console.error("Failed to switch user", e);
                        this.$store.dispatch('setSystemMessage', {text: "Konto vahetamine ebaõnnstus!", type: "ERROR"});
                    })
                }
            },
            linkedUsers: {
                immediate: true,
                handler(linkedUsers) {
                    if (linkedUsers) {
                        this.selectedUser = linkedUsers[0]._id;
                    }
                },
            },
            '$route.name': {
                immediate: true,
                handler() {
                    this.items.forEach((item, index) => {
                        if (item.children) {
                            const shouldBeActive = item.children.some(child => child.routeName === this.$route.name);
                            if (shouldBeActive) {
                                this.menuState[index] = true;
                            }
                        }
                    })
                },
            },

        },
        computed: {
            items() {

                return _.compact([
                    {icon: 'add_circle', text: 'Lisa töö', routeName: 'appRoot', exact: true},
                    {icon: mdiAnimation, text: 'Tööde haldus', routeName: 'jobs-management', exact: false},
                    {icon: mdiLandFields, text: 'Põllud', routeName: 'pollud', exact: false, setupWizard: !this.$store.getters.hasAnyFields ? mdiNumeric3CircleOutline : null},
                    // {icon: mdiTextBox, text: 'Tööde haldus', routeName: 'jobs-management', exact: false},

                    {icon: mdiAlert, text: 'Hoiatused', routeName: 'warnings', badge: true},

                    this.$store.getters.getPriaConnected ? {
                        icon: ICONS.PRIA,
                        heading: 'PRIA',
                        model: false,
                        highlight: this.$store.getters.priaJustConnected,
                        children: [
                            {icon: mdiLandPlots, text: 'Põllud', routeName: 'priaFields', exact: false},
                            {icon: mdiExpandAllOutline, text: 'Töökanded', routeName: 'priaJobs', exact: false},
                            {icon: 'm17.36,14.82l1.79,0l0,7.18l-16.15,0l0,-7.18l1.8,0l0.03,1.41l12.53,0.19m-10.5,-3.19l0.37,-1.76l8.79,1.85l-0.37,1.76l-8.79,-1.85m1.16,-4.21l0.76,-1.61l8.14,3.78l-0.76,1.62l-8.14,-3.79m2.26,-3.99l1.15,-1.38l6.9,5.76l-1.15,1.37l-6.9,-5.75m4.45,-4.25l5.36,7.21l-1.44,1.07c-1.79,-2.4 -3.57,-4.81 -5.36,-7.21', text: 'Saagikus', routeName: 'priaYield', exact: false},
                            {icon: mdiLeaf, text: 'Kultuurid', routeName: 'priaCrops', exact: false},
                        ]
                    } : undefined, // TODO: remove true

                    // {icon: 'eco', text: 'Tarvikute kulu plaan', routeName: 'cropRotation', exact: false},
                    // {icon: 'description', text: 'Tarvikute kulu plaan', routeName: 'cropRotation', exact: false},
                    // {icon: 'insert_chart', text: 'Tarvikute kulu plaan', routeName: 'requisitesPlan', exact: false},
                    // {icon: 'import_contacts', text: 'Tarvikute kulu plaan', routeName: 'cropRotation', exact: false},
                    // {icon: 'shower', text: 'Tarvikute kulu plaan', routeName: 'cropRotation', exact: false},
                    // {icon: 'table_chart', text: 'Tarvikute kulu plaan', routeName: 'cropRotation', exact: false},
                    // {icon: 'stacked_bar_chart', text: 'Tarvikute kulu plaan', routeName: 'cropRotation', exact: false},
                    {heading: 'Ülevaade', icon: 'm8.63 16.47 1.11-0.27 1.5 6.66-1.18 0.26-1.43-6.65m7.92-7.17 0.17-0.89 7.05 0.99-0.16 0.92-0.27-0.04m-16.62-7.59 3.43 3.43-4 4-3.43-3.43c-0.78-0.78-0.78-2.05 0-2.83l1.17-1.17c0.78-0.78 2.05-0.78 2.83 0m7.85 4.78 0.71 0.71-7.07 7.07-0.71-0.71-0.86-3.5 4.43-4.43 3.5 0.86z',  model: false,
                        children: _.compact([
                            {icon: mdiHomeSiloOutline, text: 'Ladu', routeName: 'sockOverview'},
                            // {icon: 'timelapse', text: 'Tööde kokkuvõte', routeName: 'jobsOverview', exact: false},
                            {icon: 'm17.36,14.82l1.79,0l0,7.18l-16.15,0l0,-7.18l1.8,0l0.03,1.41l12.53,0.19m-10.5,-3.19l0.37,-1.76l8.79,1.85l-0.37,1.76l-8.79,-1.85m1.16,-4.21l0.76,-1.61l8.14,3.78l-0.76,1.62l-8.14,-3.79m2.26,-3.99l1.15,-1.38l6.9,5.76l-1.15,1.37l-6.9,-5.75m4.45,-4.25l5.36,7.21l-1.44,1.07c-1.79,-2.4 -3.57,-4.81 -5.36,-7.21', text: 'Saagikus', routeName: 'yieldOverview', exact: false},
                            this.$store.getters.getWorkTimeEnabledByConf ? {icon: 'access_alarm', text: 'Tööaeg', routeName: 'workTime', exact: false} : undefined,
                        ])
                    },
                    {icon: mdiCalendarClock, heading: 'Planeerimine', model: false,
                        children: [
                            {icon: mdiSeedPlusOutline , text: 'Viljavaheldusplaan', routeName: 'cropRotation', exact: false},
                            {icon: mdiSproutOutline, text: 'Väetamiseplaan', routeName: 'fertilizationPlan', exact: false},
                            {icon: mdiStackOverflow, text: 'Saagikuse planeerimine', routeName: 'yieldPlan', exact: false},
                            {icon: mdiPackageVariantClosedCheck, text: 'Kuluplaan', routeName: 'requisitePlan', exact: false},
                        ]
                    },
                    // {icon: 'colorize', text: 'Mullaproovid', routeName: 'warnings'},
                    // {icon: 'filter_frames', text: 'Ladu', routeName: 'sockOverview'},
                    // {icon: 'motion_photos_on', text: 'Tööde kokkuvõte', routeName: 'jobsOverview', exact: false},
                    // {icon: 'donut_large', text: 'Tööde kokkuvõte', routeName: 'jobsOverview', exact: false},
                    // {icon: 'motion_photos_on', text: 'Tööde kokkuvõte', routeName: 'jobsOverview', exact: false},
                    {icon: mdiCog, heading: 'Haldus', model: false,
                        children: [
                            {icon: mdiSeedOutline, text: 'Seemned', routeName: 'seeds', setupWizard: !this.$store.getters.hasAnyRequisites ? mdiNumeric2CircleOutline : null},
                            {icon: mdiDotsTriangle, text: 'Väetised', routeName: 'fertilizers',},
                            {icon: mdiFlaskOutline, text: 'Taimekaitsevahendid', routeName: 'chemicals'},
                            {icon: mdiPackageVariantClosed, text: 'Teised tarvikud', routeName: 'requisites'},
                            {icon: mdiAccountMultipleOutline, text: 'Töötajad', routeName: 'workers'},
                            {icon: mdiFolderWrenchOutline , text: 'Töötüübid', routeName: 'categories', setupWizard: !this.$store.getters.hasChangedSettings ? mdiNumeric1CircleOutline : null},
                        ]
                    },
                    // {icon: 'drag_indicator', text: 'Seemned', routeName: 'seeds', setupWizard: !this.$store.getters.hasAnyRequisites ? "looks_two" : null},

                    {icon: mdiHelpCircle, heading: 'Info ja abi', model: false,
                        children: [
                            {icon: mdiDatabaseRefreshOutline, text: 'Uuenduste ajalugu', routeName: 'changelog'},
                            {icon: mdiPaletteSwatchOutline, text: 'Abivahendid', routeName: 'helpmaterials'},
                            {icon: mdiHelp, text: 'Küsimused-vastused', routeName: 'help'},
                        ]
                    },

                    // {icon: 'build', text: 'Seaded', routeName: 'settings', exact: false},
                    {icon: mdiAccountCircle, text: 'Teie konto', routeName: 'account', statusIndicator: true},

                ])
            },
            pageTitle() {
                return this.$route.meta.title(this.$route.params) || ""
            },
            isDemoMode() {
                return this.$store.getters.isDemoMode
            },
            hasCompanyInfo() {
                let user = this.$store.getters.user;
                return !!(user && user.company.code && user.company.address && user.company.address.street)
            },
            showAccountStatusWarning() {
                let accountStatus = this.$store.getters.accountStatus;
                return this.$store.getters.hasLoadedStatus && this.$store.getters.user !== null &&
                    ((accountStatus !== ACCOUNT_STATUS.TRIAL && !this.hasCompanyInfo) || accountStatus === ACCOUNT_STATUS.NEEDS_CONFIRMATION)
            },
            messageBar() {
                return this.$store.getters.hasSystemMessage;
            },
            messageBarText() {
                return this.$store.getters.getSystemMessageText;
            },
            messageBarIsTop() {
                return this.$store.getters.getSystemMessageIsTop;
            },
            messageBarIcon() {
                return this.isErrorMessage ? 'error_outline' : 'check_circle';
            },
            messageBarColor() {
                return this.messageBarIsTop ? (this.isErrorMessage ? 'error' : 'default') : this.isErrorMessage ? 'error' : 'primary';
            },
            isErrorMessage() {
                return this.$store.getters.getSystemMessageType === "ERROR";
            },
            demoWarning() {
                return this.$store.getters.isDemoMode && this.demoWarningOpen;
            },
            trialEndDate() {
                let trialDate = new Date(this.$store.getters.getTrialUntil);
                return ('0' + trialDate.getDate()).slice(-2) + '.'
                    + ('0' + (trialDate.getMonth() + 1)).slice(-2) + '.'
                    + trialDate.getFullYear();
            },
            trialWarning() {
                let user = this.$store.getters.user;
                let isDevelopmentEnv = this.$store.getters.isDevelopmentEnv;
                if (isDevelopmentEnv) {
                    return false;
                }
                if (user) {
                    let trialDate = new Date(this.$store.getters.getTrialUntil);
                    trialDate.setDate(trialDate.getDate() + 10);
                    // TODO: remove "&& this.$store.getters.linkedUsersActiveFieldTotalArea > 50" in 2025.
                    //  It was added because in 2024 the free account of 50ha was removed.
                    //  So otherwise old small accounts would see message abut ending trial
                    let hasNeverPaidForService = !this.$store.getters.hasPaidEver && this.$store.getters.linkedUsersActiveFieldTotalArea > 50;
                    return this.$store.getters.hasLoadedStatus &&
                        this.$store.getters.accountStatus === ACCOUNT_STATUS.NEEDS_CONFIRMATION &&
                        trialDate < this.$store.getters.getCurrentDate &&
                        hasNeverPaidForService &&
                        this.trialWarningOpen
                }
                return false;
            },
            increaseOrderWarning() {
                let isDevelopmentEnv = this.$store.getters.isDevelopmentEnv;
                if (isDevelopmentEnv) {
                    return false;
                }
                let user = this.$store.getters.user;
                const isStaging = process.env.NODE_ENV !== 'production';
                if (isStaging) {
                    return false;
                }
                if (user) {
                    let hasPaidForServiceThisYear = this.$store.getters.hasPaidThisYear;
                    return this.$store.getters.hasLoadedStatus &&
                        this.$store.getters.accountStatus === ACCOUNT_STATUS.NEEDS_CONFIRMATION &&
                        hasPaidForServiceThisYear &&
                        this.increaseOrderWarningOpen
                }
                return false;
            },
            increaseOrderWarningIsPersistent() {
                let lastFieldUpdateDate = this.$store.getters.getLastUpdatedFieldDate;
                let fewDaysAgo = new Date();
                fewDaysAgo.setDate(fewDaysAgo.getDate() - 4);
                return lastFieldUpdateDate !== undefined && fewDaysAgo > lastFieldUpdateDate;
            },
            updateYearlySubscriptionWarning() {
                const isStaging = process.env.NODE_ENV !== 'production';
                let user = this.$store.getters.user;
                if (isStaging || (user && user.email === 'joel@joel.ee')) {
                    return false;
                }
                if (user) {
                    // TODO: remove "|| this.$store.getters.linkedUsersActiveFieldTotalArea <= 50" in 2025.
                    //  It was added because in 2024 the free account of 50ha was removed.
                    //  So small accounts see a message about extening subscription
                    let hasPaidForService = this.$store.getters.hasPaidEver || this.$store.getters.linkedUsersActiveFieldTotalArea <= 50;
                    let hasNotPaidForServiceThisYear = !this.$store.getters.hasPaidForServiceThisYear;
                    const currentDate = this.$store.getters.getCurrentDate;
                    let firstOfFebruary = new Date(currentDate.getFullYear(), 2, 1);
                    return this.$store.getters.hasLoadedStatus &&
                        this.$store.getters.accountStatus === ACCOUNT_STATUS.NEEDS_CONFIRMATION &&
                        hasPaidForService && hasNotPaidForServiceThisYear &&
                        currentDate > firstOfFebruary &&
                        this.updateYearlySubscriptionWarningOpen
                }
                return false;
            },
            isWellIntoSeason() {
              const currentDate = this.$store.getters.getCurrentDate;
              let firstOfJune = new Date(currentDate.getFullYear(), 6, 1);
              return currentDate > firstOfJune;
            },
            updateYearlySubscriptionWarningPersistent() {
                let firstOfMarch = new Date(new Date().getFullYear(), 3, 1);
                return this.$store.getters.getCurrentDate > firstOfMarch;
            },
            trialAccountDeterminationWarning() {
                let user = this.$store.getters.user;
                if (user) {
                    let trialTerminationDate = new Date(this.$store.getters.getTrialUntil);
                    trialTerminationDate.setDate(trialTerminationDate.getDate() + 60);
                    return this.$store.getters.hasLoadedStatus &&
                        this.$store.getters.accountStatus === ACCOUNT_STATUS.NEEDS_CONFIRMATION &&
                        trialTerminationDate < this.$store.getters.getCurrentDate
                }
                return false;
            },
            warningCount() {
                if (this.$store.getters.getWarnings) {
                    return this.$store.getters.getWarnings.length;
                } else {
                    return 0
                }
            },
            hasErrorWarnings() {
                const warnings = this.$store.getters.getWarnings;
                if (warnings) {
                    return _.filter(warnings, warning => warning.type.level === "ERROR").length > 0;
                } else {
                    return false
                }
            },
            linkedUsers() {
                let linkedUsers = this.$store.getters.getLinkedUsers;
                if (linkedUsers) {
                    return linkedUsers;
                }
                return undefined;
            },
            currentUserId() {
                let user = this.$store.getters.user;
                if (user) {
                    return user._id;
                }
                return undefined;
            },
            hasLinkedUsers() {
                return this.linkedUsers && this.linkedUsers.length > 1;
            },
            hasWarnings() {
                return this.warningCount > 0;
            },
            shouldShowNewAccountWelcome() {
                if (this.$store.getters.allFields.length > 0 && this.$store.getters.getAllRequisites.length > 0) {
                    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                    this.newAccountWelcome = false;
                }
                return this.loadingFieldsCompleted && this.loadingRequisitesCompleted &&
                    (this.$store.getters.allFields.length === 0 && this.$store.getters.getAllRequisites.length === 0) &&
                    this.newAccountWelcome;
            },
            currentYear() {
                let currentFullYear = this.$store.getters.getCurrentFullYear;
                return {year: currentFullYear};
            },
            nextYearIfAlmostYearEnd() {
                let currentDate = this.$store.getters.getCurrentDate;
                if (currentDate.getMonth() > 9) {
                    return {year: currentDate.getFullYear() + 1};
                } else {
                    return this.currentYear;
                }
            },
            shouldShowSetupWizard() {
                return !this.$store.getters.isDemoMode &&
                    this.loadingFieldsCompleted && this.loadingRequisitesCompleted &&
                    (!this.$store.getters.hasAnyRequisites || !this.$store.getters.hasAnyFields || !this.$store.getters.hasChangedSettings);
            },
        },
        methods: {
            mdiLandFields() {
                return mdiLandFields
            },
            mdiCreation() {
                return mdiCreation
            },
            mdiAccountSwitch() {
                return mdiAccountSwitch
            },
            mdiExclamationThick() {
                return mdiExclamationThick
            },
            mdiAlertCircleOutline() {
                return mdiAlertCircleOutline
            },
            mdiNumeric3() {
                return mdiNumeric3
            },
            mdiNumeric2() {
                return mdiNumeric2
            },
            mdiNumeric3CircleOutline() {
                return mdiNumeric3CircleOutline
            },
            mdiNumeric2CircleOutline() {
                return mdiNumeric2CircleOutline
            },
            mdiNumeric2BoxOutline() {
                return mdiNumeric2BoxOutline
            },
            mdiNumeric1BoxOutline() {
                return mdiNumeric1BoxOutline
            },
            mdiNumeric1CircleOutline() {
                return mdiNumeric1CircleOutline
            },
            mdiNumeric1() {
                return mdiNumeric1
            },
            mdiHammerWrench() {
                return mdiHammerWrench
            },
            mdiPackageVariantClosed() {
                return mdiPackageVariantClosed
            },
            logout() {
                this.$store.dispatch('logout').then(() => {
                    // eslint-disable-next-line no-undef
                    try {FullStoryNS.shutdown()} catch (e) { /* ignored */ }
                    if (!this.$store.getters.isDemoMode) {
                        this.$router.push({name: 'login'})
                    } else {
                        this.$store.dispatch('demoModeOff');
                        this.$router.push({name: 'welcome'})
                    }
                })
            },
            linkedUserName: function (user) {
                return user.company.name;
            },
            linkedUserSelectValue: function (user) {
                return user._id;
            },
            closeDemoAndRegister() {
                this.$store.dispatch('logout').then(() => {
                    this.$store.dispatch('demoModeOff');
                    this.$router.push({name: 'registration'})
                })
            },
            extendSubscriptionRedirectClicked() {
              if (!this.isWellIntoSeason) {
                this.updateYearlySubscriptionWarningOpen = false
                this.trialWarningOpen = false
              }
            },
            increaseSubscriptionRedirectClicked() {
              if (!this.increaseOrderWarningIsPersistent) {
                this.increaseOrderWarningOpen = false
              }
            },
            isGroupActive(item) {
                return item.children.some(child => child.routeName === this.$route.name);
            },
        },
        beforeCreate() {
            this.$store.dispatch('loadAllFields').then(() => {
                this.loadingFieldsCompleted = true
            });
            this.$store.dispatch('loadSettings').then((settings) => {
                if (!this.$store.getters.isDemoMode && process.env.NODE_ENV === 'production' && document.cookie.indexOf('eksoinf') == -1) {
                    try {
                        window['_fs_debug'] = false;
                        window['_fs_host'] = 'fullstory.com';
                        window['_fs_script'] = 'edge.fullstory.com/s/fs.js';
                        window['_fs_org'] = 'J6GTR';
                        window['_fs_namespace'] = 'FullStoryNS';
                        (function(m,n,e,t,l,o,g,y){
                            if (e in m) {if(m.console && m.console.log) { m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');} return;}
                            g=m[e]=function(a,b,s){g.q?g.q.push([a,b,s]):g._api(a,b,s);};g.q=[];
                            // eslint-disable-next-line no-undef
                            o=n.createElement(t);o.async=1;o.crossOrigin='anonymous';o.src='https://'+_fs_script;
                            y=n.getElementsByTagName(t)[0];y.parentNode.insertBefore(o,y);
                            g.identify=function(i,v,s){g(l,{uid:i},s);if(v)g(l,v,s)};g.setUserVars=function(v,s){g(l,v,s)};g.event=function(i,v,s){g('event',{n:i,p:v},s)};
                            g.anonymize=function(){g.identify(!!0)};
                            g.shutdown=function(){g("rec",!1)};g.restart=function(){g("rec",!0)};
                            g.log = function(a,b){g("log",[a,b])};
                            g.consent=function(a){g("consent",!arguments.length||a)};
                            g.identifyAccount=function(i,v){o='account';v=v||{};v.acctId=i;g(o,v)};
                            g.clearUserCookie=function(){};
                            g.setVars=function(n, p){g('setVars',[n,p]);};
                            g._w={};y='XMLHttpRequest';g._w[y]=m[y];y='fetch';g._w[y]=m[y];
                            if(m[y])m[y]=function(){return g._w[y].apply(this,arguments)};
                            g._v="1.3.0";
                        })(window,document,window['_fs_namespace'],'script','user');
                        // eslint-disable-next-line no-undef
                        FullStoryNS.identify(settings.userId, {
                            displayName: settings.companyName,
                            id: settings.userId
                        });
                    } catch (err) {
                        // eslint-disable-next-line no-console
                        console.log(err);
                    }
                }
            });
            this.$store.dispatch('loadWorkers');
            this.$store.dispatch('loadRequisites').then(() => {
                this.loadingRequisitesCompleted = true
            });
            this.$store.dispatch('loadStatus');
        }
    }
</script>